const styles = {
  global: {
    body: {
      lineHeight: 'tall',
      background: '#062D36',
    },
  },
};

export default styles;
